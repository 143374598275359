import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/app/logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["AccountMenu"] */ "/app/apps/diamond-chest/src/components/AccountMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieBanner"] */ "/app/apps/diamond-chest/src/components/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastWrapper"] */ "/app/apps/diamond-chest/src/components/feedback/ToastWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSelector"] */ "/app/apps/diamond-chest/src/components/LanguageSelector.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/i18n/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeatureFlagsProvider"] */ "/app/apps/diamond-chest/src/providers/feature-flags-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionLogoutHandler"] */ "/app/apps/diamond-chest/src/providers/SessionLogoutHandler.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/app/node_modules/.pnpm/next-auth@5.0.0-beta.25_next@15.0.3_react-dom@19.0.0-rc-fb9a90fa48-20240614_react@19.0.0-rc-f_n7usrtpz6cqkadu5qm3fvmnhry/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.25.1_next@15.0.3_react-dom@19.0.0-rc-fb9a90fa48-20240614_react@19.0.0-rc-fb9a90fa_hie7s3iquqplynstorqacf66kq/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.25.1_next@15.0.3_react-dom@19.0.0-rc-fb9a90fa48-20240614_react@19.0.0-rc-fb9a90fa_hie7s3iquqplynstorqacf66kq/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.3_react-dom@19.0.0-rc-fb9a90fa48-20240614_react@19.0.0-rc-fb9a90fa48-20240614__reac_3oppydrhzshrauvez7b6rgn7pi/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.3_react-dom@19.0.0-rc-fb9a90fa48-20240614_react@19.0.0-rc-fb9a90fa48-20240614__reac_3oppydrhzshrauvez7b6rgn7pi/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../TeleNeoVariable.woff2\"}],\"variable\":\"--font-teleneo\"}],\"variableName\":\"TeleNeo\"}");
